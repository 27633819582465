import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import api from '../service/api'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import fileDownload from 'js-file-download'
import Axios from 'axios'
import Loading from './Loading'
import Cookies from 'js-cookie'

export default function PaperClients() {
  const [client, getClient] = useState({ isLoading: true, data: null })

  const handleDownload = () => {
    Axios.get('./files/Brazu3D.zxp', { responseType: 'blob' })
      .then((response) => {
        fileDownload(response.data, 'Brazu3D.zxp')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const id = parseInt(Cookies.get('client_id'))

  const handleDisconnect = () => {
    api
      .put(`/last?id=${id}`)
      .then(() => {
        api
          .put(`/mach?id=${id}&mach_name=reset&mach_os=reset&mach_id=reset`)
          .then((response) => console(response))
          .catch((err) => {
            console.error('An error has occurred' + err)
          })
      })
      .catch((err) => {
        alert('A última alteração foi feita há menos de 7 dias')
        console.error('An error has occurred' + err)
      })
  }

  useEffect(() => {
    api
      .get(`/client/${id}`)
      .then((response) => getClient({ isLoading: false, data: response.data }))
      .catch((err) => {
        console.error('An error has occurred' + err)
      })
  })

  if (client.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <Paper elevation={5} sx={{ width: '330px', mx: 'auto', mt: 10, p: 3 }}>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: 900, color: 'primary.main' }}
        >
          BRAZU 3D
        </Typography>
        <Typography sx={{ my: 1 }} align="center">
          Olá {client.data.name.split(' ')[0]}, aqui o seu Brazu 3D:
        </Typography>
        <Button
          variant="contained"
          sx={{ fontWeight: 900, width: '100%', mb: 1 }}
          onClick={handleDownload}
        >
          DOWNLOAD ZXP
        </Button>
        <Button
          href="https://help.brazu.io/pt/"
          target="_blank"
          variant="outlined"
          sx={{ width: '100%', mb: 2 }}
        >
          VER TUTORIAIS
        </Button>

        <Paper elevation={10} sx={{ p: 2 }}>
          <Typography align="center" sx={{ fontWeight: 900, mb: 1 }}>
            STATUS DE MÁQUINA
          </Typography>
          <Divider />
          {client.data.mach_name === null ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography align="center" sx={{ my: 1 }}>
                <DisabledByDefaultIcon fontSize="string" color="error" />{' '}
                Nenhuma máquina conectada
              </Typography>
              <Button disabled={true}>Desconectar Máquina</Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography align="center" sx={{ my: 1 }}>
                <CheckBoxIcon fontSize="string" color="primary" />{' '}
                {client.data.mach_name} Conectado
              </Typography>
              <Button onClick={handleDisconnect}>Desconectar Máquina</Button>
            </Box>
          )}
          <Typography variant="subtitle2" color="error" align="center" sx={{ fontSize: 12 }}>
            O Brazu One é para apenas 1 máquina, a troca de máquina só é
            permitida a cada 7 dias
          </Typography>
        </Paper>
      </Paper>
    </Container>
  )
}
