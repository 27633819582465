import { Box, Container, Typography } from '@mui/material'

export default function Loading() {
  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 15 }}>
        <img style={{ width: '250px' }} src="files/dog.svg" alt="Loading" />
      </Box>
      <Typography
        variant="h5"
        align="center"
        sx={{ fontWeight: 900, mt: -3, color: 'primary.main' }}
      >{`Carregando :)`}</Typography>
    </Container>
  )
}
