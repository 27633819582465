import { Fragment, useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export default function RoutesApp() {
  const Private = ({ element: Element }) => {
    const signed = Cookies.get('access_token')
    const navigate = useNavigate()
    const [redirected, setRedirected] = useState(false)

    useEffect(() => {
      if (!signed && !redirected) {
        setRedirected(true)
        navigate('/login')
      }
    }, [signed, redirected, navigate])

    return signed ? <Element /> : null
  }

  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route exact path="/" element={<Private element={Home} />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      </Fragment>
    </BrowserRouter>
  )
}
