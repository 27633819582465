import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../service/api'
import { Button, Container, Paper, TextField, Typography } from '@mui/material'
import Cookies from 'js-cookie'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [key, setKey] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleEmailChange = (event) => setEmail(event.target.value)
  const handleKeyChange = (event) => setKey(event.target.value)

  const handleSubmit = async (event) => {
    event.preventDefault()

    await api
      .get(`/login?email=${email}&key=${key}`)
      .then((response) => {
        if (response.data.access) {
          Cookies.set('access_token', response.data.access_token, {
            secure: true,
            sameSite: 'strict',
            expires: 1
          })
          Cookies.set('client_id', response.data.client.id, {
            secure: true,
            sameSite: 'strict',
            expires: 1
          })
          navigate('/')
        } else {
          setError('Email ou Chave incorretas')
        }
      })
      .catch((err) => {
        setError('Algo deu errado')
      })
  }

  return (
    <Container>
      <Paper elevation={5} sx={{ width: '300px', mx: 'auto', mt: 10, p: 2 }}>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontWeight: 900, color: 'primary.main' }}
        >
          BRAZU 3D LOGIN
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            required
            label="Email"
            placeholder="Digite seu Email"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            type="password"
            sx={{ mt: 2 }}
            fullWidth
            required
            label="Chave"
            placeholder="Digite sua Chave"
            value={key}
            onChange={handleKeyChange}
          />
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            variant="outlined"
            type="submit"
            sx={{ mt: 2, width: '100%' }}
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Container>
  )
}
